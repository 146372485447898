
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { },
  name: 'AddMaintenanceDay',
  filters: {
  }
})

export default class ScheduleAdd extends Vue {
  items: any = []
  currentIndex = 0
  // dailyId = ''
  get dailyId () {
    return this.$route.query.dailyId as any
  }

  get projectId () {
    return this.$route.query.projectId as any
  }

  get dailySrc () {
    const target = decodeURIComponent('/pagesA/pages/curingdaily/dailydetail?dailyId=' + this.dailyId)
    if (process.env.NODE_ENV !== 'production') {
      return 'http://localhost:8100/apph5/#/pages/loading/loading?redirectUrl=' + target
    } else {
      return '/apph5/#/pages/loading/loading?redirectUrl=' + target
    }
  }

  created () {
    // do something with
    // this.dailyId = this.$route.query.dailyId as string
    this.loadData()
  }

  mounted () {
    // this.getConserveEchars(10, 15)
    window.addEventListener('popstate', this.handlePopState)
  }

  beforeDestroy () {
    window.removeEventListener('popstate', this.handlePopState)
  }

  loadData () {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhProjectDailyByPage, {
      projectId: this.projectId
    }).then((res) => {
      this.items = res.list || []
      this.currentIndex = this.items.findIndex((item: any) => item.dailyId === this.dailyId)
    })
  }

  handlePopState () {
    // 在这里处理 iframe 的显示状态
    // 可以根据需要，隐藏或加载 iframe
    // 示例：隐藏 iframe
    console.log('iframe-id')
    const iframe = document.getElementById('iframe-id')
    if (iframe) {
      iframe.style.display = 'none'
    }
  }

  prev () {
    this.currentIndex--
    this.toDetail()
  }

  next () {
    this.currentIndex++
    this.toDetail()
  }

  toDetail () {
    const dailyId = this.items[this.currentIndex].dailyId
    // this.dailyId = dailyId
    this.$router.replace(`/maintenanceDaily/details?dailyId=${dailyId}&projectId=${this.projectId}`)
  }

  goBack () {
    this.$router.replace('/maintenanceDaily')
  }
}
